/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import "bootstrap/dist/css/bootstrap.css"
import "./layout.scss"
import image from "../images/academy/person_studying.png"
import { setConfig } from 'react-hot-loader';
import "@fontsource/source-code-pro/400.css"
import "@fontsource/source-code-pro/700.css"

setConfig({
  reloadHooks: false,
});

const Layout = ({ style, children, lang, title, link, description }) =>
  <>
    <Helmet>
      <html lang={lang} />
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={link} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta property="og:url" content={link} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@haggroup1" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Corporation",
          "url": "https://www.hag.ventures",
          "name": "HAG Ventures",
          "foundingDate": "2009",
          "logo": "https://hagventures.s3.amazonaws.com/logos/dark_logo.png",
          "founders": [
            {
            "@type": "Person",
            "name": "Rodrigo de Alvarenga"
            }
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Sales",
            "email": "contact@hag.ventures"
          },
          "sameAs": [
            "https://www.hag.studio",
            "https://www.hag.academy",
            "https://www.hag.services",
            "https://www.linkedin.com/company/haggroup",
            "https://www.instagram.com/hag.group/",
            "https://www.facebook.com/haggroup1",
            "https://twitter.com/haggroup1"
          ]
        }
      `}
      </script>

    </Helmet>
    <div id="wrapper">
      <main id="main" style={style}>{children}</main>
      <footer style={{ color: `#494949`, textAlign: `center` }}>
      </footer>
    </div>
  </>

export default Layout
